<template>
    <transition name="drop-fade">
        <div v-if="!accepted" class="cookie-banner">
            <div class="cookie-banner__wrapper">
                <h4>{{$i18n.locale === 'de' ? 'Cookies und Analyse' : 'Cookies and Analytics'}}</h4>
                <p>
                    {{
                        $i18n.locale === 'de'
                        ? 'Wir verwenden Cookies, um die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.'
                        : 'We use cookies to analyze access to our website. We also pass on information about your use of our website to our partners for analysis. Our partners may combine this information with other data that you have provided to them or that they have collected as part of your use of the services.'
                    }}
                </p>
                <button class="cookie-banner__button" @click="acceptConditions">
                    {{$i18n.locale === 'de' ? 'Zustimmen' : 'Accept'}}
                </button>
                <button class="cookie-banner__button cookie-banner__button--secondary" @click="declineConditions">
                    {{$i18n.locale === 'de' ? 'Ablehnen' : 'Decline'}}
                </button>
            </div>
        </div>
    </transition>
</template>
<script>
import {bootstrap} from 'vue-gtag'
export default {
    name: "CookieBanner",
    data() {
        return {
            accepted: false,
        }
    },
    created() {
        const cookiesAccepted = localStorage.getItem('x-cookies-accepted');
        this.accepted = cookiesAccepted;
    },
    methods: {
        acceptConditions() {
            bootstrap().then(() => {
                this.accepted = true;
                localStorage.setItem('x-cookies-accepted', true);
                localStorage.setItem('sameSite', 'Strict');
            })
        },
        declineConditions() {
            this.accepted = true;
            localStorage.setItem('x-cookies-accepted', false);
            localStorage.setItem('sameSite', 'Strict');
        }
    }
}
</script>
<style lang="scss" scoped>
.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    left: 0;
    background: #ffffff;
    padding: 1rem 1.6rem 2rem;
    z-index: 10;
    box-shadow: 0 -1px 10px 0px rgb(0 0 0 / 15%);

    &__wrapper {
        max-width: 1440px;
        width: 100%;
        margin: auto;
    }
    &__button {
        cursor: pointer;
        margin:  1rem auto 0;
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
        background-color: #0c3818;
        border-radius: 20px;
        color: #ffffff;
        padding: 8px 24px;
        transition: all 300ms;
        font-family: 'freight-text-pro';
        font-weight: 700;
        outline: none;
        border: none;
        margin-right: .8rem;
        &:hover {
            background-color: #5e6534;
        }
        &--secondary {
            background-color: #e2e2e2;
            color: #666666;
            &:hover {
                background-color: #d2d2d2;
            }
        }

    }

    h4 {
        font-family: 'neue-plak';
        font-size: 18px;
        font-weight: 700;
        color: #182952;
    }
    p {
        font-family: 'freight-text-pro';
        font-size: 16px;
        font-weight: 500;
        color: #182952;
    }
}
</style>
<template>
    <div class="stage">
        <div class="stage__text">
            <h1>
                {{text}}
            </h1>
            <span class="stage__anchors">
                <router-link :to="`/${$i18n.locale}${links[0].link.url}`">{{links[0].link.title}}</router-link>
                <a href="#what-we-do" @click.prevent="$emit('clicked')">{{links[1].link.title}}</a>
            </span>
        </div>
        <div class="stage__image-wrapper">
            <img ref="stageImage" class="stage__image" :alt="image.alt || image.name" src=""/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'StageHome',
    props: {
        image: {
            type: Object,
            default: () => {},
        },
        text: {
            type: String,
            required: true,
        },
        links: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.loadImage();
                    observer.disconnect();
                }
            });
        });
        observer.observe(this.$refs.stageImage);
    },
    methods: {
        loadImage() {
            this.$refs.stageImage.src = this.image.url;
        }
    }
}
</script>
<style lang="scss" scoped>
.stage {
    max-width: 1140px;
    display: flex;
    flex-wrap: wrap-reverse;
    margin: auto;
    @media (min-width: 1024px) {
        height: 600px;
    }
    &__text {
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: #0c3818;
        color: #ffffff;
        padding: 1.6rem;
        @media (min-width: 1024px) {
            padding: 120px 50px 0 110px;
        }
        h1 {
            font-size: 30px;
            line-height: 1.2;
            font-family: 'neue-plak';
            font-weight: 700;
            margin: 0 0 1.6rem;
            @media (min-width: 1024px) {
                font-size: 56px;
                letter-spacing: .1px;
                margin: 0 0 50px;
            }
        }
    }
    &__image-wrapper {
        display: flex;
        flex: 1;
        max-width: 472px;
        min-width: 320px;
        width: 100%;
        max-height: 320px;
        @media (min-width: 1024px) {
            max-height: 100%;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__anchors {
        font-family:"freight-text-pro";
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        a {
            color: #ffffff;
            text-decoration: none;
            cursor: pointer;
            &:last-child {
                margin-left: 50px;
            }
        }
    }
}
</style>
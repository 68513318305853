<template>
    <div v-if="posts.length" class="tagposts">
        <h3 class="tagposts__sectionhead">{{ header }}</h3>
        <div v-if="posts.length > 3" class="tagposts__controls tagposts__controls--left swiper-button-next">
            <icon-right class="tagposts__icon"/>
        </div>
        <div class="swiper-container">
            <ul class="tagposts__list swiper-wrapper">
                <li
                    v-for="(item, i) in posts"
                    :key="`post-${i}`"
                    class="tagposts__list-item swiper-slide"
                >
                    <router-link
                        :to="`/${$i18n.locale}${getPostLink(item.link, item.slug)}`"
                        class="tagposts__link"
                    >
                        <div class="tagposts__image-wrapper">
                            <img
                                v-if="isNaN(item.featured_media)"
                                class="tagposts__image"
                                :src="getFeatureImage(item)"
                            />
                        </div>
                        <div class="tagposts__content-wrapper">
                            <h3 v-html="item.title.rendered.length > 50 ? `${item.title.rendered.substring(0, 50)}...` : item.title.rendered"/>
                            <div
                                class="tagposts__excerpt-wrapper"
                                v-html="item.excerpt.rendered.length > 100 ? `${item.excerpt.rendered.substring(0, 100)}...` : item.excerpt.rendered"
                            />
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
        <div v-if="posts.length > 3" class="tagposts__controls tagposts__controls--right swiper-button-prev">
            <icon-right class="tagposts__icon"/>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import IconRight from './icons/icon-right.vue';
Swiper.use(Navigation);
export default {
    name: 'TagPosts',
    components: {
        IconRight
    },
    props: {
        header: {
            type: String,
            required: true,
        },
        route: {
            type: String,
            required: true,
        },
        posts: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            swiperInstance: null,
        }
    },
    watch: {
        posts() {
            this.fetchFetureImagesAction();
            this.$nextTick(() => {
                this.initSwiper();
            });
        }
    },
    mounted() {
        this.initSwiper()
    },
    methods: {
        ...mapActions([
            'fetchFeatureImages',
        ]),
        initSwiper() {
            this.swiperInstance = new Swiper('.swiper-container', {
                slidesPerView: 3,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.tagposts__controls--right',
                    prevEl: '.tagposts__controls--left',
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 15
                    },
                    // when window width is >= 480px
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    // when window width is >= 640px
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    }
                }
            });
        },
        fetchFetureImagesAction() {
            if(this.route) {
                const arr = [];
                this.posts.forEach((obj, i) => {
                    if (obj.featured_media) {
                    arr.push({
                        route: this.route,
                        index: i,
                        id: obj.featured_media,
                    })
                    }
                });
                this.fetchFeatureImages(arr);
            }
        },
        getPostLink(link, slug) {
            const pathArray = link.split('/');
            const cat = pathArray[pathArray.length - 3];
            return `/${cat}/${slug}`;
        },
        getFeatureImage(item) {
            return item.featured_media.source_url
        }
    }

}
</script>
<style lang="scss" scoped>

.tagposts {
    max-width: 1140px;
    margin: auto;
    position: relative;

    &__sectionhead {
    margin: 120px auto 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    text-align: center;
    font-weight: 500;
    color: #182952;
  }

  &__list {
      margin: 0;
      padding: 0 0 1.6rem;
  }

  &__list-item {
    padding: 0;
    margin: 0;
    height: auto;
    list-style-type: none;
    box-shadow: 0px 1px 4px 0px rgba(120, 120, 120, .3);
    border: 1px solid #f8f8f8;
  }

  &__link {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    text-decoration: none;
    color: inherit;
  }

  &__image-wrapper {
    width: 100%;
    background-color: #e8edf8;
    min-height: 200px;
    max-height: 210px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content-wrapper {
    padding: 1.6rem;
    font-size: 18px;
    h3 {
      font-family:"neue-plak";
      font-weight: 500;
      color: #182952;
      margin: 0 0 .8rem 0;
    }
    p {
      line-height: 1.5;
    }
  }
  &__controls {
      display: none;
      @media (min-width: 768px) {
        display: block;
        background-color: #ffffff;
        box-shadow: 0px 15px 15px -10px rgba(120,120,120,.3);
        position: absolute;
        top: 50%;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        z-index: 10;
        padding: .5rem;
        color: #182952;
        &--left {
            left: 0;
            .tagposts__icon {
                transform: rotate(180deg);
            }
        }
        &--right {
            right: 0;
        }
        @media (min-width: 1440px) {
            &--left {
                left: -4rem;
            }
            &--right {
                right: -4rem;
            }
        }
      }
  }
  &__icon {
      width: 100%;
      height: 100%;
  }
}
</style>
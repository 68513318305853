import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';

Vue.use(VueGtag, {
  config: {
    id: "G-K8DCLC3YP8",
    params: {
      anonymize_ip: true
    }
  },
  appName: 'NELA | Next Economy Lab',
  pageTrackerScreenviewEnabled: true,
  bootstrap: false,
}, router);
Vue.use(VueRouter);

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
});

Vue.use(VueMeta);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { i18n } from '@/main';

Vue.use(Vuex);

const langMapping = {
  en: 'https://nexteconomylab.de/wp/en',
  de: 'https://nexteconomylab.de/wp',
};

export default new Vuex.Store({
  state: {
    projects: {
      props: {},
      list: [],
    },
    publications: {
      props: {},
      list: [],
    },
    jobs: {
      props: {},
      list: [],
    },
    events: {
      props: {},
      list: [],
    },
    team: {
      props: {},
      list: [],
    },
    fetched: {},
    tags: [],
    cats: [],
    navigation: {},
    offers: {
      props: {},
      list: [],
    },
  },
  getters: {
    getProjects: state => state.projects.list,
    getProjectListProps: state => state.projects.props,
    getPublications: state => state.publications.list,
    getPublicationsListProps: state => state.publications.props,
    getEvents: state => state.events.list,
    getEventsListProps: state => state.events.props,
    getJobs: state => state.jobs.list,
    getJobListProps: state => state.jobs.props,
    getTeam: state => state.team.list,
    getTeamListProps: state => state.team.props,
    getFetched: state => state.fetched, // this getter is unsorted do not use in Lists
    getTags: state => state.tags,
    getCats: state => state.cats,
    getNavigation: state => state.navigation,
    getOffers: state => state.offers.list,
    getOfferListProps: state => state.offers.props,
  },
  mutations: {
    setOffers(state, res) {
      res.forEach(obj => {
        state.offers.list.push(obj);
      })
    },
    setOffersListProps(state, res) {
      state.offers.props = res;
    },
    setProjects(state, res) {
      res.forEach(obj => {
        state.projects.list.push(obj);
      })
    },
    setProjectListProps(state, res) {
      state.projects.props = res;
    },
    setPublications(state, res) {
      res.forEach(obj => {
        state.publications.list.push(obj);
      })
    },
    setPublicationsListProps(state, res) {
      state.publications.props = res;
    },
    setEvents(state, res) {
      res.forEach(obj => {
        state.events.list.push(obj);
      })
    },
    setEventsListProps(state, res) {
      state.events.props = res;
    },
    setJobs(state, res) {
      res.forEach(obj => {
        state.jobs.list.push(obj);
      })
    },
    setJobListProps(state, res) {
      state.jobs.props = res;
    },
    setTeam(state, res) {
      res.forEach(obj => {
        state.team.list.push(obj);
      })
    },
    setTeamListProps(state, res) {
      state.team.props = res;
    },
    setFetched(state, res) {
      Vue.set(state.fetched, res[0].slug, res[0])
    },
    setTagPosts(state, res){
      Vue.set(state.fetched[res.route], 'postTags', res.data)
    },
    setFeatureImages(state, data){
      Vue.set(state.fetched[data.route].postTags[data.index], 'featured_media', data.res)
    },
    setTags(state, res) {
      state.tags.push( res);
    },
    setCats(state, res) {
      state.cats = res;
    },
    setNavigation(state, navObj) {
      Vue.set(state.navigation, navObj[0], navObj[1]);
    }
  },
  actions: {
    async fetchProjects({ commit }, page) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/posts`, {
        params: {
          categories: i18n.locale === 'de' ? 3 : 4,
          per_page: 10,
          page
        }
      })
        .then(res => {
          commit('setProjects', res.data);
          commit('setProjectListProps', {
            totalItems: res.headers['x-wp-total'],
            totalPages: res.headers['x-wp-totalpages'],
          })
        })
    },
    async fetchPublications({ commit }, page) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/posts`, {
        params: {
          categories: i18n.locale === 'de' ? 32 : 5,
          per_page: 10,
          page
        }
      })
        .then(res => {
          commit('setPublications', res.data);
          commit('setPublicationsListProps', {
            totalItems: res.headers['x-wp-total'],
            totalPages: res.headers['x-wp-totalpages'],
          })
        })
    },
    async fetchEvents({ commit }, page) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/posts`, {
        params: {
          categories: i18n.locale === 'de' ? 33 : 9,
          per_page: 10,
          page
        }
      })
        .then(res => {
          commit('setEvents', res.data);
          commit('setEventsListProps', {
            totalItems: res.headers['x-wp-total'],
            totalPages: res.headers['x-wp-totalpages'],
          })
        })
    },
    async fetchJobs({ commit }, page) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/posts`, {
        params: {
          categories: i18n.locale === 'de' ? 4 : 2,
          per_page: 10,
          page
        }
      })
        .then(res => {
          commit('setJobs', res.data);
          commit('setJobListProps', {
            totalItems: res.headers['x-wp-total'],
            totalPages: res.headers['x-wp-totalpages'],
          })
        })
    },
    async fetchTeam({ commit }, page) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/posts`, {
        params: {
          categories: i18n.locale === 'de' ? 12 : 6,
          per_page: 10,
          page,
        }
      })
        .then(res => {
          commit('setTeam', res.data);
          commit('setTeamListProps', {
            totalItems: res.headers['x-wp-total'],
            totalPages: res.headers['x-wp-totalpages'],
          })
        })
    },
    async fetchOffers({ commit }, page) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/posts`, {
        params: {
          categories: i18n.locale === 'de' ? 50 : 6,
          per_page: 10,
          page,
        }
      })
        .then(res => {
          commit('setOffers', res.data);
          commit('setOffersListProps', {
            totalItems: res.headers['x-wp-total'],
            totalPages: res.headers['x-wp-totalpages'],
          })
        })
    },
    async fetchTags({ commit }, id) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/tags/${id}`)
        .then(res => {
          commit('setTags', res.data);
        })
    },
    async fetchCats({ commit }) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/categories`)
        .then(res => {
          commit('setCats', res.data);
        })
    },
    async fetchCurrentPost({ commit, dispatch }, route) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/posts?slug=${route}`)
        .then(res => {
          if(res.data.length > 0) {
            if(res.data[0].acf.post_tags && res.data[0].acf.post_tags.length) {
              const routeAndTags = {
                route: route,
                tags: res.data[0].acf.post_tags 
              };
              dispatch('fetchPostsByTags', routeAndTags);
            }
            commit('setFetched', res.data);
          }
        })
    },
    async fetchPostsByTags({ commit }, obj){
      const joinedTags = obj.tags.join();
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/posts?tags=${joinedTags}&per_page=6&page=1`)
        .then(res => {
          if(res.data.length > 0) {
            const resObj = {
              route: obj.route,
              data: res.data,
            };
            commit('setTagPosts', resObj);
          }
        });
    },
    async fetchFeatureImages({ commit }, arr) {
      arr.forEach(obj => {
        axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/media/${obj.id}`)
        .then(res => {
          if(res.data) {
            const resObj = {
              route: obj.route,
              index: obj.index,
              res: res.data,
            }
            commit('setFeatureImages', resObj);
          }
        });
      })
    },
    async fetchCurrentPage({ commit, dispatch }, route) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/wp/v2/pages?slug=${route}`)
        .then(res => {
          if(res.data.length > 0) {
            if(res.data[0].acf.post_tags && res.data[0].acf.post_tags.length) {
              const routeAndTags = {
                route: route,
                tags: res.data[0].acf.post_tags
              };
              dispatch('fetchPostsByTags', routeAndTags);
            }
            commit('setFetched', res.data);
          }
        })
    },
    async fetchNavigation({ commit }, menu) {
      axios.get(`${langMapping[i18n.locale]}/wp-json/routes/menus/${menu}`)
        .then(res => {
          if(res.data.length > 0) {
            commit('setNavigation', [menu, res.data]);
          }
        })
    }
  },
  modules: {
  }
})

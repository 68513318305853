<template>
  <div class="home" v-if="currentRoute">
    <stage-home
      :image="currentRoute.acf.stage.image"
      :text="currentRoute.acf.stage.title"
      :links="currentRoute.acf.stage.links"
      @clicked="scrollTo($refs.whatWeDo)"
    />
    <introduction :image="currentRoute.acf.description.image" subline="Mission" :maintext="currentRoute.acf.description.text"/>
    <div class="home__content">
      <h2 ref="whatWeDo" class="home__sectionhead">{{currentRoute.acf.what}}</h2>
      <inline-blocks :blocks="currentRoute.acf.blocks"/>
      <fact-testimonial
        :image="currentRoute.acf.fact.image.url"
        :alt="currentRoute.acf.fact.image.alt || currentRoute.acf.fact.image.name"
        :quote="currentRoute.acf.fact.quote"
        :quoteId="1"
        :link="currentRoute.acf.fact.link"
        :color-theme="currentRoute.acf.fact.colorTheme"
        :reversed="currentRoute.acf.fact.reversed" 
        :textQuote="currentRoute.acf.fact.text_quote"
        :author="currentRoute.acf.fact.author"
      />
      <fact-testimonial
        :image="currentRoute.acf.fact_2.image.url"
        :alt="currentRoute.acf.fact_2.image.alt || currentRoute.acf.fact.image.name"
        :quote="currentRoute.acf.fact_2.quote"
        :quoteId="2"
        :fact="currentRoute.acf.fact_2.fact"
        :color-theme="currentRoute.acf.fact_2.colorTheme"
        :reversed="currentRoute.acf.fact_2.reversed" 
        :textQuote="currentRoute.acf.fact_2.text_quote"
        :author="currentRoute.acf.fact_2.author"
      />
      <h3 class="home__sectionhead">{{currentRoute.acf.more}}</h3>
      <inline-blocks :blocks="currentRoute.acf.blocks_2"/>
      <kontakt
        ref="contact-section"
        :text="currentRoute.acf.contact.text"
        :link="currentRoute.acf.contact.link"
        @in-view="openNewsletterPopup()"
      />
      <tag-posts class="home__posts" :header="$i18n.locale === 'de' ? 'Aktuelles' : 'What´s new'" :posts="tagPosts" :route="$route.name"/>
      <fact-testimonial
        :image="currentRoute.acf.fact_3.image.url"
        :alt="currentRoute.acf.fact_3.image.alt || currentRoute.acf.fact.image.name"
        :quote="currentRoute.acf.fact_3.quote"
        :quoteId="3"
        :fact="currentRoute.acf.fact_3.fact"
        :color-theme="currentRoute.acf.fact_3.colorTheme"
        :reversed="currentRoute.acf.fact_3.reversed"
        :textQuote="currentRoute.acf.fact_3.text_quote ? currentRoute.acf.fact_3.text_quote : ''"
        :author="currentRoute.acf.fact_3.author ? currentRoute.acf.fact_3.author : ''"
      />
      <testimonial
        :image="currentRoute.acf.testimonial.image"
        :text="currentRoute.acf.testimonial.text"
        :author="currentRoute.acf.testimonial.position"
      />
      <h3 :ref="$i18n.locale === 'de' ? 'referenzen' : 'references'" class="home__sectionhead">{{$i18n.locale === 'de' ? 'Referenzen' : 'References'}}</h3>
      <references :references="currentRoute.acf.references"/>
    </div>
    <div ref="popup" @click.self="closeNewsletterPopup()" class="popup" v-if="popUpOpen">
      <div class="popup__content">
        <button class="popup__close" @click.prevent="closeNewsletterPopup()"> 
          <icon-close/>
        </button>
        <h1>Newsletter</h1>
        <h2>Werden Sie Teil einer zukunftsfähigen Wirtschaft</h2>
        <newsletter/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions} from 'vuex'
import StageHome from '@/components/stage-home.vue'
import Introduction from '@/components/introduction.vue'
import InlineBlocks from '@/components/inline-blocks.vue'
import FactTestimonial from '@/components/fact-testimonial.vue'
import Testimonial from '@/components/testimonial.vue'
import References from '@/components/references.vue'
import Kontakt from '@/components/kontakt.vue'
import TagPosts from '@/components/tag-posts.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import IconClose from '../components/icons/icon-close.vue'
import Newsletter from '../components/newsletter.vue'

export default {
  name: 'Home',
  components: {
    StageHome,
    Introduction,
    InlineBlocks,
    FactTestimonial,
    Testimonial,
    References,
    Kontakt,
    TagPosts,
    IconClose,
    Newsletter
  },
  data() {
    return {
      popUpOpen: false,
      popUpClosedOnce: false
    }
  },
  computed: {
    ...mapGetters([
        'getFetched'
    ]),
    currentRoute(){
      const getFetched =  this.getFetched['home'];
      return getFetched;
    },
    tagPosts(){
      if (this.currentRoute) {
        return this.currentRoute.postTags ? this.currentRoute.postTags : [];
      }
      return [];
    }
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPage('home');
      }
  },
  watch: {
    currentRoute(val) {
      if(val && this.$route.hash) {
        this.$nextTick(() => {
          const hash = this.$route.hash.replace('#', '');
          this.scrollTo(this.$refs[hash]);
        })
      }
    },
  },
  methods: {
    ...mapActions([
          'fetchCurrentPage',
    ]),
    scrollTo(ref) {
      window.scrollTo({
        top: ref.offsetTop - 100,
        left: 0,
        behavior: 'smooth'
      });
    },
    openNewsletterPopup() {
      if(this.popUpClosedOnce) return;
      this.popUpOpen = true;
      this.$nextTick(() => {
        disableBodyScroll(this.$refs.popup);
      });
    },
    closeNewsletterPopup() {
      this.popUpClosedOnce = true;
      enableBodyScroll(this.$refs.popup);
      this.popUpOpen = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  margin-top: 86px;
  padding: 0 .8rem;
  position: relative;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;
  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    text-align: center;
    font-weight: 500;
    color: #182952;
    margin-bottom: 65px;
  }
  &__posts {
    margin-bottom: 65px;
  }
  &__note {
    font-size: 15px;
    font-family: 'freight-text-pro';
    margin-left: .4rem;
    color: #666666;
    text-decoration: none;
  }
}

.popup {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.5);
  backdrop-filter: blur(5px);
  z-index: 20;
  padding: 2rem;

  &__content {
    position: absolute;
    inset: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    max-width: 450px;
    width: 100%;
    height: max-content;
    padding: 3rem 2rem 2rem;
    margin: auto;
    border-radius: 10px;
    flex-direction: column;

    h1 {
      margin: 0 0 .5rem;
      color: #0c3818;
    }
    h2 {
      margin: 0;
      color: #0c3818;
    }
  }

  &__close {
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    width: 2rem;
    height: 2rem;
    top: 1rem;
    right: 1rem;
    position: absolute;
  }
}
</style>

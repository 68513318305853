<template>
    <div class="testimonial">
        <div v-if="image" class="testimonial__image-wrapper">
            <img class="testimonial__image" :alt="image.alt || image.name" :src="image.url" />
        </div>
        <div v-if="text" class="testimonial__quote">
            <blockquote>
                {{ text }}
            </blockquote>
        </div>
        <div v-if="author" class="testimonial__author">
            {{ author }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'testimonial',
    props: {
        image: {
            type: Object,
        },
        text: {
            type: String,
        },
        author: {
            type: String,
        }
    }
}
</script>
<style lang="scss" scoped>
.testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.6rem;

    @media (min-width: 1024px) {
        margin-bottom: 150px;
    }

    &__image-wrapper {
        width: 250px;
        height: 250px;
        margin-bottom: 65px;
    }

    &__image {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
    }

    &__quote {
        color: #182952;
        max-width: 540px;
        margin: auto;
        line-height: 1.2;
        text-align: center;
        font-family: "freight-text-pro";
        font-weight: 700;
        font-style: italic;
        font-size: 32px;
        margin-bottom: 1.6rem;

        blockquote {
            margin: 0;
            padding: 0;
        }
    }

    &__author {
        font-size: 18px;
    }
}
</style>
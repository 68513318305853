<template>
    <div class="header">
        <nav>
            <div class="header__logo_wrapper">
                <router-link :to="`/${$i18n.locale}`">
                    <img :src="logo" class="header__logo" alt="NELA"/>
                </router-link>
            </div>
            <div class="header__nav">
                <Navigation view="desktop"/>
            </div>
            <div class="header__nav_mobile_toggle">
                <button class="header__mobile-button" @click="navToggle = !navToggle">
                    <IconBars/>
                </button>
                <a href="mailto:info@nexteconomylab.de">
                    <button class="header__mobile-button">
                        <IconEnvelope/>
                    </button>
                </a>
            </div>
            <div class="header__contact">
                <LinkButton link="mailto:info@nexteconomylab.de">
                    {{$i18n.locale === 'de' ? 'Kontakt': 'Contact'}}
                </LinkButton>
            </div>
            <div class="header__lang">
                <a
                    href="/de"
                    class="header__lang-link"
                    :class="{'header__lang-link--active': $i18n.locale === 'de'}"
                    @click.prevent="changeLocale('de')"
                >
                    DE
                </a>
                /
                <a
                    href="/en"
                    class="header__lang-link"
                    :class="{'header__lang-link--active': $i18n.locale !== 'de'}"
                    @click.prevent="changeLocale('en')"
                >
                    EN
                </a>
            </div>
        </nav>
        <transition name="drop-fade">
            <div class="header__nav_mobile" v-if="navToggle">
                <Navigation view="mobile"/>
            </div>
        </transition>
    </div>
</template>

<script>
import Logo from '@/assets/images/nela_logo.png'
import LinkButton from '@/components/link-button.vue'
import Navigation from '@/components/navigation.vue'
import IconBars from '@/components/icons/icon-bars.vue'
import IconEnvelope from '@/components/icons/icon-envelope.vue'

export default {
    name: 'Header',
    components: {
        LinkButton,
        Navigation,
        IconBars,
        IconEnvelope
    },
    data() {
        return {
            logo: Logo,
            navToggle: false,
        }
    },
    watch: {
        $route() {
            this.navToggle = false;
        }
    },
    methods: {
        changeLocale(locale) {
            if(this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
                this.$router.push(`/${locale}`);
                this.$router.go();
            }
        }
    }

}
</script>

<style lang="scss" scoped>
    .header {
        position: fixed;
        color: #182952;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0 1px 10px 0px rgba(0,0,0,.15);
        font-size: 18px;
        z-index: 10;
        nav {
            display: flex;
            flex: 1;
            padding: 0 .8rem;
            height: 86px;
            max-width: 1440px;
            margin: auto;
            @media (min-width: 1024px) {
                padding: 0 20px;    
            }
        }
        &__logo_wrapper {
            height: 100%;
            width: auto;
            display: flex;
            align-items: center;
        }
        &__logo {
            height: 50px;
            width: auto;
        }
        &__nav, &__contact {
            display: none;
            @media (min-width: 1024px) {
                display: flex;
            }
        }
        &__nav {
            flex: 1;
            align-items: center;
        }
        &__nav_mobile {
            display: flex;
            flex: 1 0 auto;
            @media (min-width: 1024px) {
                display: none;
            }
        }
        &__nav_mobile_toggle {
            display: flex;
            flex: 1 0 auto;
            justify-content: flex-end;
            align-items: center;
            @media (min-width: 1024px) {
                display: none;
            }
        }
        &__mobile-button {
            background-color: #0c3818;
            padding: .5rem;
            border-radius: 50%;
            color: #ffffff;
            height: 40px;
            width: 40px;
            margin-left: .8rem;
            outline: none;
            border: none; 
        }
        &__nav_mobile {
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
            background-color: #ffffff;
            padding: 0 .8rem;
        }
        &__lang {
            display: flex;
            align-items: center;
            margin-left: 2rem;
        }
        &__lang-link {
            margin: 0 .3rem;
            text-decoration: none;
            color: inherit;
            font-family: inherit;
            font-weight: bold;
            &:hover, &--active {
                color: #f3972b;
                text-decoration: underline;
            }
        }
    }
</style>

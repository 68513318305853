<template>
    <div class="kontakt" ref="contact-section">
        <label class="kontakt__label">{{$i18n.locale === 'de' ? 'Kontakt' : 'Contact'}}</label>
        <h3 class="kontakt__text">{{text}}</h3>
        <link-button :link="link.url">
            {{link.title}}
        </link-button>
    </div>
</template>
<script>
import LinkButton from '@/components/link-button.vue'
export default {
    name: 'kontakt',
    components: {
        LinkButton,
    },
    props: {
        text: {
            type: String,
            required: true
        },
        link: {
            type: Object,
            required: true
        }
    },
    mounted() {
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                this.$emit('in-view');
            }
          });
        }, {
            rootMargin: '-400px 0px -400px 0px',
            threshold: 1.0
        });

        observer.observe(this.$refs['contact-section']);
    }
}
</script>
<style lang="scss" scoped>
.kontakt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.6rem;
    @media (min-width: 1024px) {
        margin-bottom: 150px;
    }
    &__label {
        font-size: 18px;
        color: #0c3818;
    }

    &__text {
        color: #5e6534;
        font-family: 'neue-plak';
        font-weight: 500;
        font-size: 32px;
        margin: 0 0 30px 0;
        text-align: center;
    }
}
</style>
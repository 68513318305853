<template>
    <div class="social-icons">
        <ul class="social-icons__list">
            <li v-for="(icon, i) in icons" :key="i" class="social-icons__list-item">
                <a :href="icon.href || icon.link" :name="icon.label || 'social media'" class="social-icons__link">
                    <component :is="icon.name || icon.icon" />
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'SocialIcons',
    components: {
        IconFacebook: () => import("@/components/icons/icon-facebook.vue"),
        IconInstagram: () => import("@/components/icons/icon-instagram.vue"),
        IconTwitter: () => import("@/components/icons/icon-twitter.vue"),
        IconLinkedIn: () => import("@/components/icons/icon-linkedin.vue"),
        IconSpotify: () => import("@/components/icons/icon-spotify.vue"),
    },
    props: {
        icons: {
            type: Array,
            default: () => []
        }
    },
}
</script>
<style lang="scss" scoped>
.social-icons {
    margin-top: .8rem;

    &__list,
    &__list-item {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    &__list {
        display: flex;
    }

    &__link {
        color: #0c3818;
        margin-right: 25px;

        svg {
            height: 30px;
            width: 30px;
        }
    }
}
</style>
import { render, staticRenderFns } from "./stage-home.vue?vue&type=template&id=315f97b8&scoped=true"
import script from "./stage-home.vue?vue&type=script&lang=js"
export * from "./stage-home.vue?vue&type=script&lang=js"
import style0 from "./stage-home.vue?vue&type=style&index=0&id=315f97b8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "315f97b8",
  null
  
)

export default component.exports
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { i18n } from "@/main.js";
import RouterView from '@/views/router-view.vue'

Vue.use(VueRouter)

let lang;

const routes = [
  {
    path: '/:lang',
    component: RouterView,
    beforeEnter(to, from, next) {
      lang = to.params.lang;
      if (!["en", "de"].includes(lang)) return next("de");
      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }
      return next();
    },
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
      {
        path: 'mission',
        name: 'mission',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "mission" */ '../views/mission.vue')
      },
      {
        path: 'beirat-fellows',
        name: 'beirat-fellows',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "beirat-fellows" */ '../views/beirat-fellows.vue'),
      },
      {
        path: 'supporters-companions',
        name: 'supporters-companions',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "supporters-companions" */ '../views/beirat-fellows.vue'),
      },
      {
        path: 'buendnisse-partner',
        name: 'buendnisse-partner',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "beirat-fellows" */ '../views/buendnisse-partner.vue'),
      },
      {
        path: 'alliances-partners',
        name: 'alliances-partners',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "supporters-companions" */ '../views/buendnisse-partner.vue'),
      },
      {
        path: 'jobs',
        name: 'jobs',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "jobs" */ '../views/jobs.vue'),
      },
      {
        path: 'jobs/:name',
        name: 'job-detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "job-detail" */ '../views/job-detail.vue'),
      },    
      {
        path: 'projekte',
        name: 'projekte',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "projekte" */ '../views/projekte.vue'),
      },
      {
        path: 'projekte/:name',
        name: 'projekt-detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "project-detail" */ '../views/projekt-detail.vue'),
      },
      {
        path: 'projects',
        name: 'projects',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "projekte" */ '../views/projekte.vue'),
      },
      {
        path: 'projects/:name',
        name: 'projects-detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "project-detail" */ '../views/projekt-detail.vue'),
      },
      {
        path: 'publikationen',
        name: 'publikationen',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "projekte" */ '../views/publikationen.vue'),
      },
      {
        path: 'publications',
        name: 'publications',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "projekte" */ '../views/publikationen.vue'),
      },
      {
        path: 'publikationen/:name',
        name: 'publikationen-detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "publikationen" */ '../views/publikationen-detail.vue'),
      },
      {
        path: 'publications/:name',
        name: 'publications-detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "publications" */ '../views/publikationen-detail.vue'),
      },
      {
        path: 'veranstaltungen',
        name: 'veranstaltungen',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "events-de" */ '../views/events.vue'),
      },
      {
        path: 'events',
        name: 'events',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "events-en" */ '../views/events.vue'),
      },
      {
        path: 'veranstaltungen/:name',
        name: 'events-detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "project-detail" */ '../views/event-detail.vue'),
      },
      {
        path: 'events/:name',
        name: 'events-detail-en',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "project-detail" */ '../views/event-detail.vue'),
      },
      {
        path: 'team',
        name: 'team',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "team" */ '../views/team.vue'),
      },
      {
        path: 'team/:name',
        name: 'team-detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "team-detail" */ '../views/team-detail.vue'),
      },
      {
        path: 'leistung/organisationsberatung',
        name: 'organisationsberatung',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "thema-detail" */ '../views/organisationsberatung.vue')
      },
      {
        path: 'leistung/:name',
        name: 'leistungen-detail-de',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "leistungen-detail-de" */ '../views/leistungen-detail.vue'),
      },
      {
        path: 'services/organisational-consulting',
        name: 'organisational-consulting',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "thema-detail" */ '../views/organisationsberatung.vue')
      },
      {
        path: 'services/:name',
        name: 'leistungen-detail-en',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "leistungen-detail-en" */ '../views/leistungen-detail.vue'),
      },
      {
        path: 'organisationsstruktur',
        name: 'organisationsstruktur',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "organisationsstruktur" */ '../views/organisations-struktur.vue'),
      },
      {
        path: 'organization-structure',
        name: 'organization-structure',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "organisationsstruktur" */ '../views/organisations-struktur.vue'),
      },
      {
        path: 'solidarische-projektfinanzierung',
        name: 'solidarische-projektfinanzierung',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "organisationsstruktur" */ '../views/organisations-struktur.vue'),
      },
      {
        path: 'impressum',
        name: 'impressum',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "content-page" */ '../views/content-page.vue'),
      },
      {
        path: 'imprint',
        name: 'imprint',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "content-page" */ '../views/content-page.vue'),
      },
      {
        path: 'datenschutz',
        name: 'datenschutz',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "content-page" */ '../views/content-page.vue'),
      },
      {
        path: 'privacy-policy',
        name: 'privacy-policy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "content-page" */ '../views/content-page.vue'),
      },
      {
        path: 'subject/:name',
        name: 'thema-detail-en',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "subject-detail" */ '../views/thema-detail.vue')
      },
      {
        path: 'thema/:name',
        name: 'thema-detail-de',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "thema-detail" */ '../views/thema-detail.vue')
      },
    ],
  },
  {
    path: "*",
    redirect: "/de"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

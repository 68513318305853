<template>
    <div class="fact-testimonial"
        :class="[reversed ? 'fact-testimonial--reversed' : '', `fact-testimonial--${colorTheme}`]">
        <div class="fact-testimonial__info">
            <div v-if="link">
                <label class="fact-testimonial__link-label">{{ link.label }}</label>
                <h3 class="fact-testimonial__link-text">{{ link.text }}</h3>
                <link-button class="fact-testimonial__link-button" :link="link.button.url">{{ link.button.title }}
                </link-button>
            </div>
            <div v-if="fact" class="fact-testimonial__fact">
                <div v-if="fact.count" class="fact-testimonial__fact-count">{{ fact.count }}</div>
                <h3 class="fact-testimonial__fact-text">{{ fact.text }}</h3>
            </div>
        </div>
        <div class="fact-testimonial__quote" :class="{ 'fact-testimonial__quote--text': !svgQuote }">
            <component v-if="svgQuote" :quoteId="`-${quoteId}`" :is="quote" />
            <div v-else class="fact-testimonial__textquote">{{ textQuote }}</div>
            <div class="fact-testimonial__author" v-if="author">{{ author }}</div>
        </div>
        <div class="fact-testimonial__image-wrapper">
            <img class="fact-testimonial__image" :alt="alt" :src="image" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'factTestimonial',
    components: {
        LinkButton: () => import('@/components/link-button.vue'),
        ZitatJonas: () => import('@/components/zitate/zitat-jonas.vue'),
        ZitatJonas2: () => import('@/components/zitate/zitat-jonas2.vue'),
        ZitatHannah: () => import('@/components/zitate/zitat-hannah.vue'),
        ZitatTanja: () => import('@/components/zitate/zitat-tanja.vue'),
        ZitatTanja2: () => import('@/components/zitate/zitat-tanja2.vue'),
        ZitatSarah2: () => import('@/components/zitate/zitat-sarah2.vue'),
        ZitatSarah3: () => import('@/components/zitate/zitat-sarah3.vue'),
        // Zitate EN
        ZitatBridges: () => import('@/components/zitate/en/zitat-bridges.vue'),
        ZitatCommunication: () => import('@/components/zitate/en/zitat-communication.vue'),
        ZitatGeneration: () => import('@/components/zitate/en/zitat-generation.vue'),
        ZitatLighthouses: () => import('@/components/zitate/en/zitat-lighthouses.vue'),
        ZitatSocialjustice: () => import('@/components/zitate/en/zitat-socialjustice.vue'),
        ZitatSustainability: () => import('@/components/zitate/en/zitat-sustainability.vue'),
        ZitatExiting: () => import('@/components/zitate/en/zitat-exiting.vue'),
    },
    props: {
        image: {
            type: String,
            default: '',
        },
        alt: {
            type: String,
            required: true,
        },
        quote: {
            type: String,
            required: true,
        },
        quoteId: {
            type: Number,
        },
        fact: {
            type: Object,
        },
        link: {
            type: Object,
        },
        reversed: {
            type: Boolean,
        },
        colorTheme: {
            type: String,
            default: 'blue'
        },
        textQuote: {
            type: String,
            default: '',
        },
        author: {
            type: String,
            default: '',
        }
    },
    computed: {
        svgQuote() {
            return !this.textQuote.length;
        }
    }
}
</script>
<style lang="scss" scoped>
@font-face {
    font-family: "indie-flower";
    src: url("../assets/fonts/IndieFlower-Regular.woff") format("woff");
}

.fact-testimonial {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    margin-bottom: 1.6rem;

    @media (min-width: 1024px) {
        height: 420px;
        margin-bottom: 150px;
    }

    &--reversed {
        flex-direction: row-reverse;

        .fact-testimonial__info {
            padding: 1.6rem;
        }
    }

    &__image-wrapper {
        display: none;
        max-width: 271px;
        flex: 1;
        min-width: 271px;
        height: 100%;

        @media (min-width: 1024px) {
            display: flex;
        }
    }

    &__info {
        display: flex;
        flex: 1;
        padding: 1.6rem;
        margin-bottom: 1.6rem;

        @media (min-width: 1140px) {
            min-width: 370px;
            padding: 65px 0 65px 95px;
            margin-bottom: 0;
        }
    }

    &__quote {
        padding: 1.6rem;
        display: flex;
        flex: 1;
        min-width: 300px;
        flex-direction: column;

        @media (min-width: 1140px) {
            padding: 0 38px;
        }

        svg {
            width: 100%;
            height: 100%;
            max-width: 350px;
            margin: auto;
        }

        &--text {
            flex-direction: column;
        }
    }

    &__textquote {
        font-family: "indie-flower";
        font-size: 1.8rem;
        padding: 1.6rem 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 300px;
    }

    &__author {
        margin-top: 1rem;
        font-size: 1.2rem;
        font-family: "freight-text-pro";
        font-weight: 500;
    }

    &__fact-count {
        line-height: 1.2;
        font-family: 'neue-plak';
        font-weight: 700;
        font-size: 50px;

        @media (min-width: 1024px) {
            font-size: 112px;
        }
    }

    &__fact-text {
        line-height: 1.2;
        font-family: 'neue-plak';
        font-weight: 500;
        font-size: 24px;
        color: #3b5379;

    }

    &__image {
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__link-label {
        font-size: 16px;
        font-family: "freight-text-pro";
        font-weight: bold;
        margin-bottom: .8rem;

        @media (min-width: 1024px) {
            font-size: 18px;
            margin-bottom: 20px;
            ;
        }
    }

    &__link-text {
        font-size: 24px;
        line-height: 1.2;
        font-family: 'neue-plak';
        font-weight: 500;
        margin: 0 0 1.6rem;
        max-width: 540px;

        @media (min-width: 1024px) {
            font-size: 32px;
            letter-spacing: .1px;
            margin: 0 0 50px;
        }
    }

    &--blue {
        background-color: #182952;
        color: #ffffff;

        .fact-testimonial__link-button {
            background-color: #ffffff;
            color: #182952;
        }

        .fact-testimonial__fact-text {
            color: #ffffff;
        }
    }

    &--white {
        background-color: #ffffff;
        color: #182952;

        .fact-testimonial__link-button {
            background-color: #182952;
            color: #ffffff;
        }
    }

    &--off-white {
        background-color: #f2f0e9;
        color: #182952;

        .fact-testimonial__link-button {
            background-color: #182952;
            color: #ffffff;
        }
    }
}
</style>
<template>
    <a v-if="!internal && !anchor" target="_blank" class="link-button" :class="`link-button--${type}`" :href="link">
        <slot/>
        <icon-right class="link-button__icon" v-if="type === 'link'"/>
    </a>
    <button v-else-if="anchor" target="_blank" class="link-button" :class="`link-button--${type}`" @click.prevent="$emit('clicked')">
        <slot/>
    </button>
    <router-link v-else class="link-button" :class="`link-button--${type}`" :to="link">
        <slot/>
        <icon-right class="link-button__icon" v-if="type === 'link'"/>
    </router-link>

</template>
<script>
import IconRight from '@/components/icons/icon-right.vue'
export default {
    name: 'LinkButton',
    components: {
        IconRight
    },
    props: {
        link: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'button'
        },
        internal: {
            type: Boolean,
            default: false,
        },
        anchor: {
            type: Boolean,
            default: false,
        }
    }
}
</script>
<style lang="scss" scoped>
.link-button {
    margin: auto;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: inherit;
    &--button{
        background-color: #0c3818;
        border-radius: 20px;
        color: #ffffff;
        padding: 8px 24px;
        transition: all 300ms;
        font-size: inherit;
        font-family: inherit;
        &:hover {
            background-color: #5e6534;
        }
    }
    &--link {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-style: italic;
        color: #f3972b;
        text-decoration: none;
        margin: 0 auto 0 0;
        &:hover {
            text-decoration: underline;
        }
    }
    &__icon {
        width: 12px;
        height: 12px;
        margin-left: 4px;
        transform: translate(0,1px)
    }
}
</style>
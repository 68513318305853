<template>
    <div class="footer">
        <div class="footer__prime">
            <div class="footer__logo-wrapper">
                <router-link :to="`/${$i18n.locale}`">
                    <img :src="logo" class="footer__logo" alt="NELA" />
                </router-link>
            </div>
            <nav class="footer__nav">
                <div v-for="item in navigationItems" :key="item.ID" class="footer__col">
                    <span class="footer__headline">{{ item.title }}</span>
                    <ul v-if="submenu[item.ID]">
                        <li v-for="subitem in submenu[item.ID]" :key="subitem.ID">
                            <router-link :to="`/${$i18n.locale}${subitem.url}`" v-html="subitem.title" />
                        </li>
                    </ul>
                </div>
            </nav>
            <div class="footer__connect">
                <span class="footer__headline">{{ $i18n.locale === 'de' ? 'Folge uns' : 'Follow us' }}</span>
                <social-icons :icons="footerIcons" />
                <newsletter />
            </div>
        </div>
        <div class="footer__sec">
            <router-link :to="$i18n.locale === 'de' ? `/${$i18n.locale}/impressum` : `/${$i18n.locale}/imprint`">{{
                $i18n.locale === 'de' ? 'Impressum' : 'Imprint' }}</router-link>
            <router-link :to="$i18n.locale === 'de' ? `/${$i18n.locale}/datenschutz` : `/${$i18n.locale}/privacy-policy`">{{
                $i18n.locale === 'de' ? 'Datenschutz' : 'Privacy policy' }}</router-link>
        </div>
    </div>
</template>
<script>
import Logo from '@/assets/images/nela_logo.png';
import SocialIcons from '@/components/social-icons.vue'
import Newsletter from '@/components/newsletter.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'Footer',
    components: {
        SocialIcons,
        Newsletter,
    },
    data() {
        return {
            logo: Logo,
            footerIcons: [
                {
                    label: 'Facebook',
                    name: 'IconFacebook',
                    href: 'https://www.facebook.com/nela.nexteconomylab'
                },
                {
                    label: 'linkedin',
                    name: 'IconLinkedIn',
                    href: 'https://www.linkedin.com/company/zoe-institute/?viewAsMember=true'
                },
                {
                    label: 'spotify',
                    name: 'IconSpotify',
                    href: 'https://open.spotify.com/show/6qNvXIFvbqWuFHY9WUlBus?si=43a46ad4cea84856'
                }
            ],
            submenu: {},
        }
    },
    computed: {
        ...mapGetters([
            'getNavigation'
        ]),
        navigationItems() {
            if (this.getNavigation.footer) {
                return this.getSubMenu('0');
            }
            return [];
        },
    },
    watch: {
        navigationItems(val) {
            if (val.length) {
                this.$nextTick(() => {
                    val.map(elm => {
                        this.$set(this.submenu, elm.ID, this.getSubMenu(elm.ID))
                    });
                })
            }
        }
    },
    created() {
        this.fetchNavigation('footer');
    },
    methods: {
        ...mapActions([
            'fetchNavigation'
        ]),
        getSubMenu(id) {
            return this.getNavigation.footer.filter(item => item.menu_item_parent === String(id));
        },
    }
}
</script>
<style lang="scss" scoped>
.footer {
    background-color: #f2f0e9;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 100px 0;
    padding: 1.6rem;

    @media (min-width: 1024px) {
        padding: 100px 65px;
    }

    @media (min-width: 1140px) {
        padding: 100px 0;
    }

    &__prime,
    &__sec {
        max-width: 1140px;
        margin: auto;
    }

    &__prime {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.6rem;

        @media (min-width: 1024px) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__sec {
        display: flex;
        width: 100%;

        @media (min-width: 1024px) {
            justify-content: flex-end;
        }

        a {
            color: #000000;
            text-decoration: none;
            transition: all 300ms;
            margin-left: 40px;

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                color: #f3972b;
            }
        }
    }

    &__logo-wrapper {
        width: 120px;
        height: 100%;
        display: flex;
        margin-right: 65px;
        margin-bottom: 1.6rem;
    }

    &__logo {
        width: 100%;
        height: 100%;
    }

    &__nav {
        display: flex;
        flex: 1;
        margin-bottom: 1.6rem;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        @media (min-width: 768px) {
            justify-content: space-around;
        }

        @media (min-width: 1024px) {
            align-items: flex-start;
            justify-content: flex-start;
        }

        @media (min-width: 1140px) {
            justify-content: space-around;
        }
    }

    &__col {
        margin-bottom: 1.6rem;
        margin-right: 1.6rem;
        min-width: 100px;
        max-width: 150px;

        &:last-child {
            margin-right: 0;
        }
    }

    ul {
        font-size: 14px;
        margin: .8rem 0 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
            list-style-type: none;

            a {
                color: #000000;
                text-decoration: none;
                transition: all 300ms;

                &:hover {
                    color: #f3972b;
                }
            }
        }
    }

    &__connect {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        max-width: 350px;
    }

    &__headline {
        font-size: 16px;
        font-weight: bold;
        color: #5e6534;
    }
}
</style>